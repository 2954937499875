import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from './create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./landing-page-playboom')),
  playboom24: loadable(() => import('./landing-page-playboom')),
  freedomcasino: loadable(() => import('./landing-page-playboom')),
  wettenlive: loadable(() => import('./landing-page-wettenlive')),
  casinosky: loadable(() => import('./landing-page-casinosky')),
  casinoskyjp: loadable(() => import('./landing-page-casinoskyjp')),
  paradiseplay: loadable(() => import('./landing-page-paradiseplay')),
}

export const LandingPage = createBrandedComponent(
  'LandingPage',
  BRANDED_COMPONENTS
)
