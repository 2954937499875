import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import styled from '@emotion/styled'

const Text = styled(common.Text)``

Text.defaultProps = {
  color: 'heading-text',
  fontFamily: 'head',
}

const levels = {
  1: {
    as: 'h1',
    fontSize: 8,
    fontWeight: 'bold',
    lineHeight: 1.26,
  },
  2: {
    as: 'h2',
    fontSize: 7,
    fontWeight: 'bold',
    lineHeight: 1.14,
  },
  3: {
    as: 'h3',
    fontSize: 5,
    fontWeight: 'bold',
    lineHeight: 1.45,
  },
  4: {
    as: 'h4',
    fontSize: 4,
    fontWeight: 'bold',
    letterSpacing: '1px',
    lineHeight: 1.14,
    textTransform: 'uppercase',
  },
}

export function Heading(props) {
  const styleProps = levels[props.level]

  return <Text {...styleProps} {...props} />
}

Heading.defaultProps = {
  as: 'div',
}

Heading.propTypes = {
  as: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  level: PropTypes.number,
}
