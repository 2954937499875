import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./footer-playboom')),
  playboom24: loadable(() => import('./footer-playboom')),
  freedomcasino: loadable(() => import('./footer-playboom')), // TODO: northraven.
  wettenlive: loadable(() => import('./footer-wettenlive')),
  casinosky: loadable(() => import('./footer-playboom')),
  casinoskyjp: loadable(() => import('./footer-casinoskyjp')),
  paradiseplay: loadable(() => import('./footer-paradiseplay')),
}

export const Footer = createBrandedComponent('Footer', BRANDED_COMPONENTS)
