import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import { lazy as loadable } from '@loadable/component'

import * as session from '../session'

const Component = loadable(() => import('./wallet'))

export const Wallet = R.compose(
  connect(state => ({ authenticated: session.isAuthenticated(state.session) })),
  recompose.branch(
    props => R.not(props.authenticated),
    recompose.renderNothing
  ),
  recompose.mapProps(R.omit(['authenticated']))
)(Component)
