import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as session from './session'
import { Casino } from './casino'
import { LandingPage } from './landing-page'

export const Home = R.compose(
  connect(state => ({ authenticated: session.isAuthenticated(state.session) })),
  recompose.branch(
    props => props.authenticated,
    recompose.renderComponent(Casino),
    recompose.renderComponent(LandingPage)
  ),
  recompose.mapProps(R.omit(['authenticated']))
)()

// For @loadable/components
export default Home
