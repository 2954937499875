import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import { lazy as loadable } from '@loadable/component'

import * as Configuration from '../configuration'

const Component = loadable(() => import('./brite-login-drawer'))

export const BriteLoginDrawer = R.compose(
  connect(state => ({
    briteSigninEnabled: Configuration.getBriteSigninEnabled(
      state.configuration
    ),
  })),
  recompose.branch(
    props => R.not(props.briteSigninEnabled),
    recompose.renderNothing
  ),
  recompose.mapProps(R.omit(['briteSigninEnabled']))
)(Component)
