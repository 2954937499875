import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./promotions-drawer')),
  playboom24: loadable(() => import('./promotions-drawer')),
  freedomcasino: loadable(() => import('./promotions-drawer')),
  wettenlive: loadable(() => import('./promotions-drawer-wettenlive')),
  casinosky: loadable(() => import('./promotions-drawer-casinosky')),
  casinoskyjp: loadable(() => import('./promotions-drawer-casinosky')),
  paradiseplay: loadable(() => import('./promotions-drawer-casinosky')),
}

export const PromotionsDrawer = createBrandedComponent(
  'PromotionsDrawer',
  BRANDED_COMPONENTS
)
