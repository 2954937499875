import * as React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { NotAuthenticated } from '../not-authenticated'

export function PrivateRoute({
  authenticated,
  component: Component,
  ...props
}) {
  return (
    <Route
      {...props}
      render={({ ...props }) => {
        if (authenticated) {
          return <Component {...props} />
        }

        return <NotAuthenticated />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  path: PropTypes.string,
}
