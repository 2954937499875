import * as R from 'ramda'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as ServerConfiguration from '../server-configuration'
import * as Theming from '../theming'
import { Divider } from '../divider'
import { Heading } from '../heading'

const Language = styled.span`
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export function LocationPicker() {
  const i18n = I18n.useI18n()
  const configuration = ServerConfiguration.useContext()

  return (
    <Theming.Alternative>
      <Common.Box bg="g-bg" color="g-text" px={[1, 2]} py={1} width="100%">
        <Heading
          as="h1"
          level={2}
          color="heading-text"
          data-testid="change-location.title"
        >
          {i18n.translate('change-location.title')}
        </Heading>
        <Common.Space py={[1, 2]}>
          <Divider />
        </Common.Space>
        <Common.Box
          display="grid"
          gridTemplateColumns={[
            'repeat(2, minmax(25%, 1fr))',
            'repeat(auto-fit, minmax(25%, 1fr))',
          ]}
          gridGap={[0, 1]}
          style={{ listStyle: 'none' }}
        >
          {R.map(
            country => (
              <Common.Box as="li" key={country.alpha2} pr={0} py={0}>
                <Common.Box
                  fontWeight="bold"
                  data-testid={`change-location.country.${country.alpha2}`}
                >
                  {i18n.translate(`l10n.region.${R.toLower(country.alpha2)}`)}
                </Common.Box>
                <ul>
                  {R.map(
                    locale => (
                      <Common.Box as="li" key={locale.code} fontSize={1} pt={0}>
                        <a
                          href={`/${locale.slug}/?confirm_locale=1`}
                          hrefLang={locale.slug}
                        >
                          <Language
                            data-testid={`change-location.language.${locale.language}`}
                          >
                            {i18n.translate(
                              `l10n.language.${R.toLower(locale.language)}`
                            )}
                          </Language>
                        </a>
                      </Common.Box>
                    ),
                    country.locales
                  )}
                </ul>
              </Common.Box>
            ),
            configuration.countries
          )}
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

// For @loadable/component
export default LocationPicker
