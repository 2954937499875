import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import * as I18n from '@rushplay/i18n'
import * as common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import { Divider } from './divider'
import { useTranslationImgproxyUrl } from './use-imgproxy-url'

const CasinoCategory = loadable(() => import('./casino-category'))
const CasinoNavigation = loadable(() => import('./casino-navigation'))
const SearchPage = loadable(() => import('./search-page'))

export function Casino() {
  const i18n = I18n.useI18n()

  const background = useTranslationImgproxyUrl(
    i18n.translate('login-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '1200',
    }
  )

  const backgroundMobile = useTranslationImgproxyUrl(
    i18n.translate('login-page.default-background'),
    {
      resizingType: 'auto',
      resizingWidth: '950',
    }
  )

  return (
    <common.Box
      width="100%"
      overflow="hidden"
      style={{
        backgroundAttachment: 'fixed',
        // ios fix
        height: 'intrinsic',
      }}
      backgroundColor="card-background"
      backgroundImage={[`url(${backgroundMobile})`, `url(${background})`]}
      backgroundSize={'cover'}
      backgroundRepeat="no-repeat, no-repeat, no-repeat"
      flexGrow="1"
      pt={[1, 2]}
      pb={[5, 2]}
    >
      <common.Box zIndex="1" position="relative" pb={[1, 2]}>
        <Route path="/" component={CasinoNavigation} />
        <common.Space pt={0} px={[1, 2]}>
          <Divider />
        </common.Space>
      </common.Box>
      <Switch>
        <Route path="/casino/search" component={SearchPage} />
        <Route path="/casino/:id/:slug" component={CasinoCategory} />
      </Switch>
    </common.Box>
  )
}

// for @loadable/components
export default Casino
