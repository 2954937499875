import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from './create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./registration-drawer-boom')),
  playboom24: loadable(() => import('./registration-drawer-playboom24')),
  freedomcasino: loadable(() => import('./registration-drawer-boom')),
  wettenlive: loadable(() => import('./registration-drawer-boom')),
  casinosky: loadable(() =>
    import('./localized-registration-drawer-casinosky')
  ),
  casinoskyjp: loadable(() => import('./registration-drawer-casinoskyjp')),
  paradiseplay: loadable(() => import('./registration-drawer-playboom24')),
}

export const RegistrationDrawer = createBrandedComponent(
  'RegistrationDrawer',
  BRANDED_COMPONENTS
)

export default RegistrationDrawer
